


canvas {
    position:absolute;
    background: "black";
    height: 100%;
    width: 100%;
    /* display: block; */
    z-index: -1;
    /* filter: blur(20px)contrast(10); */
    transform: scale(1.1); 

    filter: blur(20px);

 
}


@media only screen and (max-width: 600px) {
    canvas {
       
        transform: scale(1.3); 
    
     
    }
  }